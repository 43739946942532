import React from 'react';
import { Link } from 'gatsby';
import RenderConfigFE from '../../../../src/components/render-config-fe';
import { getConfigFrontEnd } from '../../../../src/components/use-config-fe';

export interface IFooterProps {
    children?: any;
}

const Footer = (props: IFooterProps) => {
    const logoImage = getConfigFrontEnd({ name: 'LogoImage' });
    const configLogoLink = getConfigFrontEnd({ name: 'LogoLink' });
    const partner_image_1 = getConfigFrontEnd({ name: 'partner_logo_1' });
    const partner_image_2 = getConfigFrontEnd({ name: 'partner_logo_2' });
    const partner_image_3 = getConfigFrontEnd({ name: 'partner_logo_3' });
    const partner_image_4 = getConfigFrontEnd({ name: 'partner_logo_4' });
    return (
        <section className="section-footer custom">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <RenderConfigFE className="copy-right" name="Footer_left_content" type="html" />
                    </div>
                    <div
                        className="col-sm-12 col-md-4 col-lg-4"
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Link to={configLogoLink} target="__blank">
                            <img width="260" src={logoImage.url}></img>
                        </Link>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <div
                            className="o-flex"
                            style={{ marginBottom: '40px', display: 'flex', justifyContent: 'flex-end' }}>
                            <img
                                style={{ marginRight: '10px', objectFit: 'contain' }}
                                width="73"
                                src={partner_image_1.url}></img>
                            <img
                                style={{ marginRight: '10px', objectFit: 'contain' }}
                                width="73"
                                src={partner_image_2.url}></img>
                            <img
                                style={{ marginRight: '10px', objectFit: 'contain' }}
                                width="73"
                                src={partner_image_3.url}></img>
                            <img style={{ objectFit: 'contain' }} width="73" src={partner_image_4.url}></img>
                        </div>
                        <div>
                            <RenderConfigFE
                                style={{ textAlign: 'right' }}
                                className="copy-right"
                                name="Footer_right_content"
                                type="html"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Footer;
