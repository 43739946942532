import { Link } from 'gatsby';
import React from 'react';
// import RenderConfigFE from '../../../../src/components/render-config-fe';
import classNames from 'classnames';
import { getConfigFrontEnd } from '../../../../src/components/use-config-fe';

export interface IHeaderProps {
    children?: any;
}

const Header = (props: IHeaderProps) => {
    const configLogoLink = getConfigFrontEnd({ name: 'LogoLink' });
    const logoImage = getConfigFrontEnd({ name: 'LogoImage' });
    const headerClass = classNames({
        'container-fluid': true
    });

    return (
        <section className="section-header custom">
            <div className={headerClass}>
                <div className="section-header-container o-flex">
                    <div className="section-header-container--email">
                        <a href="mailto:info@boesenboes.be" target="_self">
                            info@boesenboes.be
                        </a>
                    </div>
                    <div className="section-header-container--logo">
                        <Link to={configLogoLink} target="__blank">
                            <img src={logoImage.url}></img>
                        </Link>
                    </div>
                    <div className="section-header-container--phone">
                        <a href="tel:+3211232604">+32 (0) 11 23 26 04</a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Header;
