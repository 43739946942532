/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useLayoutEffect } from 'react';
import Header from './header';
import Footer from './footer';
import '../../../../i18n';
import '../../../../src/_ultility/moment';
import '../../../../src/css/mini-default.css';
import '../../../../src/css/layout.css';
import CookiePopup from '../../../../src/components/cookie-warning-popup';
import { getConfigFrontEnd } from '../../../../src/components/use-config-fe';
import '../../css/custom-layout.scss';

export interface ILayoutProps {
    children?: any;
}

const Layout = (props: ILayoutProps) => {
    const configButtonFontColor = getConfigFrontEnd({ name: 'ButtonFontColor' });
    const configButtonBackgroundColor = getConfigFrontEnd({ name: 'ButtonBackgroundColor' });

    useLayoutEffect(() => {
        if (!!configButtonBackgroundColor) {
            document.documentElement.style.setProperty('--button-back-color', configButtonBackgroundColor);
        }
        if (!!configButtonFontColor) {
            document.documentElement.style.setProperty('--button-fore-color', configButtonFontColor);
        }

        return () => {};
    }, []);

    return (
        <>
            <Header />
            {props.children}
            <Footer />
            <CookiePopup />
        </>
    );
};

export default Layout;
