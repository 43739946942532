const path = require('path');

module.exports = {
    siteMetadata: {
        title: `Recevez votre estimation gratuite`,
        description: `Nous vous proposons une estimation professionnelle gratuite de votre bien sur base de notre étude de marché CENTURY 21, toujours réalisée par nos experts immobiliers. Qu'attendez-vous? La seul chose que vous devez faire est remplir l’info ici, nous faisons le reste.`,
        author: `trungdq@omnicasa.com`,
        siteUrl: `https://estimation-apropertynpelsmaekers.omnicasaweb.com/`
    },
    googleAnalyticsID: 'G-JEQ9PTYLMF',
    websiteUrl: 'https://estimation-apropertynpelsmaekers.omnicasaweb.com/',
    googleFont: {
        fonts: [
            `Nunito Sans`,
            `ital,wght@0,400;0,600;0,700;0,800;1,400;1,600;1,700`,
            `Barlow`,
            `ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900`,
            `Open Sans`,
            `ital,wght@0,400;0,600;0,700;0,800;1,400;1,600;1,700`
        ],
        display: `swap`,
        format: ['woff', 'woff2', 'ttf']
    },
    canonicalUrls: {
        siteUrl: `https://estimation-apropertynpelsmaekers.omnicasaweb.com/`
    },
    manifest: {
        name: `Bosenbose`,
        short_name: `Bosenbose`,
        start_url: `/`,
        background_color: `#ffffff`,
        theme_color: `#bcaf8f`,
        display: `standalone`,
        icon: `${path.join(__dirname, '.')}/images/favicon.png` // This path is relative to the root of the site.
    }
};
